import React from 'react';

const Footer = () => {
    return (
        <footer id={'footer'}>
            <p>Copyright &copy; {(new Date().getFullYear())} Infused Art | All rights reserved</p>
        </footer>
    )
}

export default Footer;